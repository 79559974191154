<template>
  <div class="row back-row">
    <div class="col-12 back-col">
      <div class="back-link">
        <i class="bi bi-arrow-left"></i>
        <router-link :to="{ name: fromName }" class="hovered-link"
          >Back to {{ fromText }}</router-link
        >
      </div>
    </div>
    <div class="col-12 section-title active slide-down-d0">
      <h2>{{ project.title }}</h2>
    </div>
  </div>
  <div class="row projects-details">
    <div class="col-12">
      <h3 class="txt-primary mb-4 txt-mob-center">About</h3>
      <p v-for="description in project.description" :key="description">
        {{ description }}
      </p>
      <p class="txt-muted txt-small">
        <span class="txt-bold">Date: </span> {{ project.date }}
      </p>
      <div class="btn-center mb-4 project-links">
        <a
          v-for="button in project.buttons"
          :key="button.link"
          :target="button.type === 'external' ? '_blank' : ''"
          class="mx-2"
          :href="button.link"
        >
          {{ button.text }}
        </a>
      </div>
    </div>
    <div class="gallery row" v-if="project.images?.length">
      <div class="col-12 col-xl-6 featured">
        <img
          class="img-fluid"
          :src="featuredImg"
          :alt="project.title"
          v-if="featuredImg"
        />
      </div>
      <div class="col-12 col-xl-6 thumbnails">
        <div class="thumbnails-wrapper">
          <img
            class="item-wrapper"
            v-for="(image, index) in project.images"
            :key="image"
            :src="image"
            :alt="`${project.title}-${index + 1}`"
            @click="setImage(index)"
            :class="{ active: featuredImg === image }"
          />
        </div>
      </div>
    </div>
    <div class="col-12 mt-4" v-if="project?.contribution?.length">
      <h3 class="txt-primary mb-4 txt-mob-center">Contribution</h3>
      <p v-for="contribution in project.contribution" :key="contribution">
        {{ contribution }}
      </p>
      <p class="txt-muted txt-small">
        <span class="txt-bold">Technical stack: </span
        ><template v-for="(stack, index) in project.detailed_stack" :key="stack"
          >{{ stack
          }}<template v-if="index + 1 < stackLength">, </template></template
        >
      </p>
    </div>
  </div>
</template>

<script>
import Session from '@/utils/services/Session';
import Api from '@/utils/services/Api';

export default {
  name: 'ProjectDetails',
  data() {
    return {
      fromName: 'Projects',
      fromText: 'projects',
      featuredImg: '',
      currentIndex: 0,
      project: {},
    };
  },
  computed: {
    stackLength() {
      return this.project.detailed_stack.length;
    },
  },
  async created() {
    await this.getData();
    if (!this.project.show_details)
      return this.$router.push({ name: 'Projects' });

    if (this.project.images) this.featuredImg = this.project.images[0];

    const { name } = Session.getFromRoute();

    if (name === 'undefined') return;

    this.fromName = name;
    this.fromText = name.toLowerCase();
  },
  methods: {
    async getData() {
      this.project = await Api.getOne(this.$route.params.id);
    },
    setImage(index) {
      this.featuredImg = null;
      this.$nextTick(() => {
        this.featuredImg = this.project.images[index];
      });
    },
  },
};
</script>

<style lang="scss">
.back-row {
  margin-top: $sp;
  .back-col {
    margin-bottom: $sp;
    .back-link {
      width: fit-content;
      i {
        margin-right: 0.5rem;
        color: $secondary-50;
        transition: $trs-default;
      }
      &:hover {
        i {
          color: $primary;
        }
      }
    }
  }
  .section-title {
    margin-bottom: $sp;
  }
}
</style>
